import { Paper, Stack, CircularProgress, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useAuth0 } from '@auth0/auth0-react';


const EmberList = forwardRef((props, ref) => {
    const internalRef = useRef(); // TODO: can we remove this?

    useImperativeHandle(ref, () => ({
        refresh: () => {
            fetchEmbers();
        }
    }));

    const [embers, setEmbers] = useState([]);
    var [loading, setLoading] = useState(true);
    const { user } = useAuth0();

    useEffect(() => {
        fetchEmbers();
    }, []);

    function fetchEmbers() {
        setLoading(true)

        const headers = new Headers();
        headers.append("x-functions-key", "rJZSmPc8t2F5-zg8MhwGL2J1MCs_QNgVexqRAt8Hs96pAzFunUVhSg==");

        const requestOptions = {
            method: "GET",
            headers: headers,
            redirect: "follow"
        };
        // const baseUri = "http://localhost:7071";
        const baseUri = "https://api-ember.azurewebsites.net"

        const url = baseUri + "/api/get-embers?userId=" + user.sub;

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(result => {
                setEmbers(result.list);
                setLoading(false);
            })
            .catch(error => console.log('error', error));
    }

    function convertDate(date) {
        const [year, month, day] = date.split('-').map(Number);
        const d = new Date(year, month - 1, day); // month is 0-based in Date constructor
        const options = { weekday: 'short', month: 'long', day: 'numeric' };
        const formattedDate = d.toLocaleDateString('en-US', options).toUpperCase();

        return formattedDate;
    }

    return (
        <Stack> {/* Days */}
            {loading &&
                <Stack spacing={1} padding={2} paddingBottom={4}>
                    <Skeleton width={100} />
                    <Skeleton />
                    <Skeleton />
                </Stack>
            }
            <Stack
                spacing={1.5}
            >
                {!loading && embers &&
                    embers.map((day, i) => (
                        <Paper
                            elevation={0}
                            sx={{
                                paddingTop: 2.5,
                                paddingBottom: 2,
                                paddingLeft: 3,
                                paddingRight: 3,
                                borderRadius: 2,
                                border: '1px solid #F0ECF0',
                                background: 'white',
                            }}
                        >
                            <Stack spacing={1}>
                                <Stack direction="row">
                                    <span className="ember-font" style={{ fontWeight: '500', color: 'rgb(100,100,100)', fontSize: '12px' }} >{convertDate(day.day)}</span>
                                </Stack>
                                <Stack direction="row" >
                                    <Stack spacing={1} sx={{ width: 1 }}>
                                        {
                                            day.embers.map((ember, i) => (
                                                <span key={i} className="ember-font" style={{ color: '#3f0300', paddingTop: '1px', paddingBottom: '5px' }}>{ember.ember}</span>
                                            ))
                                        }
                                        {/* <Box sx={{ height: "10px" }} /> */}
                                    </Stack>

                                </Stack>
                            </Stack>
                        </Paper>
                    ))
                }
            </Stack>

        </Stack>
    )
});

export { EmberList };