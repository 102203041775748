import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Home from './Home/Home';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Root = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  if (isLoading) {
    console.log('Loading...');
    return <div>Loading...</div>
  }

  if (isAuthenticated) {
    return <Home />
  } else {
    loginWithRedirect();
  }
}

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-xx23ddzahzq07bwf.us.auth0.com"
      clientId="SGA1DGatrploIiUqGlTMgLBjfoDCB1c0"
      authorizationParams={{
        redirect_uri: window.location.origin
      }}>
      <Root />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
