import React from 'react';
import { Stack } from "@mui/material";
function DateHeader(props) {
    const { user } = props;

    const currentDate = new Date();
    const options = { weekday: 'short', month: 'short', day: 'numeric' };
    const formattedDate = currentDate.toLocaleDateString('en-US', options);

    return (
        <Stack
            direction={'row'}
            justifyContent={'space-between'}
            sx={{
                paddingBottom: '20px',
            }}
        >
            <Stack>
                <span

                    className="ember-font-fancy"
                    style={{
                        color: '#3f0300',
                        fontSize: '2.8rem',
                        fontWeight: '700',
                    }}
                >
                    Hi, {user.given_name}
                </span>
                <span
                    className="ember-font-fancy"
                    style={{
                        color: '#3f0300',
                        fontSize: '2.2rem',
                        fontWeight: '600',
                    }}
                >
                    {formattedDate}
                </span>
            </Stack>
        </Stack>
    )
}

export { DateHeader };