import * as React from 'react';

import { Button, Paper, Stack, InputBase } from "@mui/material";
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

function EnterEmber(props) {
    const { user, refreshEmbers } = props;
    const [value, setValue] = React.useState();
    const [loading, setLoading] = React.useState(false);

    function postEmber() {
        const ember = value;
        if (!ember || ember.length === 0) {
            return;
        }

        setLoading(true);
        setValue("");

        const myHeaders = new Headers();
        myHeaders.append("x-functions-key", "DMfnW3fRPKxclXzGLRec7arYPa83LpqZLWLvznYG4YTMAzFud27uUQ==");
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "ember": value,
            "userId": user.sub
        });

        const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
        };

        console.log(requestOptions);

        fetch("https://api-ember.azurewebsites.net/api/add-ember", requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log(result);
            setLoading(false);
            refreshEmbers();
        })
        .catch(error => console.log('error', error));

    }

    return (
        <div>
            <Paper
                elevation={0}
                sx={{
                    padding: 1.5,
                    paddingLeft: 3,
                    paddingBottom: 1.2,
                    paddingRight: 3,
                    borderRadius: 2.5,
                    border: '1px solid #F0ECF0',
                    background: '#FAF6FA',
                }}

            >
                <Stack direction="row" spacing={1}>
                <InputBase
                    multiline
                    placeholder="What did you do today?"
                    fullWidth
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
                <Button 
                    disabled={loading}
                    variant="contained"
                    style={{background: "#3f0300", maxHeight: "40px"}}
                    onClick={postEmber}
                    >
                        <LocalFireDepartmentIcon /> 
                    </Button>
                </Stack>
                
            </Paper>
            <br /><br />
        </div>
    )
}

export { EnterEmber };