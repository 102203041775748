import * as React from 'react';

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import { useAuth0 } from '@auth0/auth0-react';

import { EmberList } from './EmberList';
import { DateHeader } from './DateHeader';
import { EnterEmber } from './EnterEmber';


const Home = () => {
    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const emberListRef = React.useRef();

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (!isAuthenticated) {
        loginWithRedirect();
        return
    }

    function refreshEmbers() {
        emberListRef.current.refresh();
    }

    return (
        <Grid2
            id="home-grid"
            container
            spacing={2}
            justifyContent="center"
            alignItems="flex-start"
            // backgroundColor="#fffbff"
        >
            <Grid2
                item
                xs={11} sm={10} md={8} lg={7} xl={6}
            >
                <div id="main-content"
                    style={{
                        paddingTop: '30px',
                    }}>
                    <DateHeader user={user} />
                    <EnterEmber user={user} refreshEmbers={refreshEmbers} />
                    <EmberList ref={emberListRef} />
                </div>
            </Grid2>
        </Grid2>
    );
}

export default Home;